import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Weighted Bar Dips 10-10-10 to a 10RM`}</p>
    <p>{`Pendlay Rows 10-10-10 to a 10RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`10-Bench Press (185/105)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`20-KBS (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open WOD 18.4 will be announced tonight at
Games.CrossFit.com at 8:00pm!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      